import { observable, action, computed } from 'mobx'
import { Model, Store } from 'store/Base'
import { serialNumberFormatToRegex } from 'helpers'
import { convertToMinutes, HOURS, MILLISECONDS, MINUTES, SECONDS, toMinutes } from 'helpers/timeConversion'

import { ProcessVersion } from './ProcessVersion'
import { CapabilityStore } from './Capability'
import { WorkStation } from './WorkStation'
import { SectionStore } from './Section'
import { Form } from './Form'
import { NestType } from './NestType';
import { Integration } from './Integration';
import { isFeatureFlagEnabled } from '../helpers/featureFlags';
import { TYPES } from '../feature/processStep';
import Decimal from 'decimal.js';
import { OperatorTimeLogStore } from './OperatorTimeLog'

export const TYPE_PRINT = 'print'
export const TYPE_SCAN = 'form'
export const TYPE_MULTIPLIER = 'multiplier'
export const TYPE_SPLIT = 'split'
export const TYPE_SUBPROCESSES = 'subprocesses'
export const TYPE_EXPORT = 'export'
export const TYPE_IMPORT = 'import';

export const TYPE_CARRIER = 'carrier';
export const TYPE_BYPRODUCT = 'byproduct';
export const TYPE_NEST = 'nest';


export const SHIPMENT_LINES_HEADER_OPTIONS = [
  'quantity',
  'description',
  'total_weight',

  // loading_carrier
  'loading_carrier_serial',

  // article
  'article_code', 'article_name', 'article_size', 'article_gross_weight',
  'article_unit', 'article_search_code',
  'article_description',

  // sales/purchase order line
  'line_notes',

  // batch
  'serial_numbers',
]

export const SHIPMENT_LINES_DETAIL_OPTIONS = [
  // batch
  'serial_number', 'loading_carrier_serial', 'best_before_date', 'lotcode', 'all_metavalues',

  // storage location and quantity
  'pick_detail',
]

// Step types behind a feature flag
export const FEATURE_FLAG_TYPES = {
  [TYPE_CARRIER]: 'carrier_integration',
  [TYPE_EXPORT]: 'import_export',
  [TYPE_IMPORT]: 'import_export',
  [TYPE_NEST]: 'nesting',
}

export const TIME_UNITS = [HOURS, MINUTES, SECONDS, MILLISECONDS]
export const TIME_UNITS_ABBREVIATIONS = {
  [HOURS]: 'h',
  [MINUTES]: 'min',
  [SECONDS]: 's',
  [MILLISECONDS]: 'ms'
}


export class Step extends Model {
  static backendResourceName = 'step'
  static ignoreErrors = ['nextStep']
  static TYPES = TYPES

  /**
   * Filter out step types behind a feature flag
   */
  @computed
  static get TYPES_ENABLED() {
    return TYPES.filter(
      type => {
        if (type in FEATURE_FLAG_TYPES) {
            return  isFeatureFlagEnabled(FEATURE_FLAG_TYPES[type])
        }
        return true;
      }
    )
  }


  @observable id = null
  @observable type = null
  @observable label = ''
  @observable setupMinutes = new Decimal(0)
  @observable setupInCustomTimeUnit = 0
  @observable setupTimeUnit = 'minutes'
  @observable delayMinutes = new Decimal(0)
  @observable delayInCustomTimeUnit = 0
  @observable delayTimeUnit = 'minutes'
  // Frontend will always show the inputed value for estimated time and time unit as 'displayEstTime' and 'displayTimeUnit'
  // And the estimated time will always be converted to minutes and saved in the backend as 'workMinutes'
  @observable workMinutes = new Decimal(5)
  @observable workInCustomTimeUnit = 5
  @observable workTimeUnit = 'minutes'
  // TODO: displayEstTime and displayTimeUnit seems to do similar as workInCustomTimeUnit and workTimeUnit, consider rework
  @observable displayEstTime = 5
  @observable displayTimeUnit = 'minutes'
  @observable operatorIdleMinutes = 0
  @observable operatorIdleInCustomTimeUnit = 0
  @observable operatorIdleTimeUnit = 'minutes'
  @observable workTempoManual = null
  @observable operatorSupervision = true
  @observable newBatchSerialNumberFormat = [
    { type: 'date', part: 'day', format: 'dd' },
    { type: 'date', part: 'month', format: 'mm' },
    { type: 'date', part: 'year', format: 'yy' },
    { type: 'code', alphabet: '0123456789', digits: 4, expand: true },
  ]
  @observable isLeadingBatch = false
  @observable isTimed = false

  @computed get convertEstTimeToMinutes() {
    return convertToMinutes(this.displayEstTime, this.displayTimeUnit)
  }

  @computed get delayInCustomUnitAsMinutes() {
    return toMinutes(this.delayInCustomTimeUnit, this.delayTimeUnit)
  }

  @computed get setupInCustomUnitAsMinutes() {
    return toMinutes(this.setupInCustomTimeUnit, this.setupTimeUnit)
  }

  @computed get workInCustomUnitAsMinutes() {
    return toMinutes(this.workInCustomTimeUnit, this.workTimeUnit)
  }

  @computed get operatorIdleInCustomUnitAsMinutes() {
    return toMinutes(this.operatorIdleInCustomTimeUnit, this.operatorIdleTimeUnit)
  }

  @computed get newBatchSerialNumberRegex() {
    return serialNumberFormatToRegex(this.newBatchSerialNumberFormat)
  }

  relations() {
    return {
      processVersion: ProcessVersion,
      nextStep: Step,
      formStep: FormStep,
      splitStep: SplitStep,
      printStep: PrintStep,
      subprocessesStep: SubprocessesStep,
      multiplierStep: MultiplierStep,
      carrierStep: CarrierStep,
      byproductStep: ByProductStep,
      nestStep: NestStep,
      exportStep: ExportStep,
      importStep: ImportStep,
      capabilities: CapabilityStore,
      workStation: WorkStation,
      sections: SectionStore,
      previousSteps: StepStore,
      timeLogs: OperatorTimeLogStore,
    }
  }

  @action fromBackend(...args) {
    super.fromBackend(...args)

    // this is a backward compatibility code
    if (this.delayMinutes !== null) {
      this.delayMinutes = new Decimal(this.delayMinutes)
      if (this.delayTimeUnit === MINUTES && this.delayMinutes.toNumber() !== this.delayInCustomTimeUnit) {
        this.delayInCustomTimeUnit = this.delayMinutes.toNumber();
      }
    }

    if (this.setupMinutes !== null) {
      this.setupMinutes = new Decimal(this.setupMinutes)
      if (this.setupTimeUnit === MINUTES && this.setupMinutes.toNumber() !== this.setupInCustomTimeUnit) {
        this.setupInCustomTimeUnit = this.setupMinutes.toNumber();
      }
    }

    if (this.workMinutes !== null) {
      this.workMinutes = new Decimal(this.workMinutes)
      if (this.workTimeUnit === MINUTES && this.workMinutes.toNumber() !== this.workInCustomTimeUnit) {
        this.workInCustomTimeUnit = this.workMinutes.toNumber();
      }
    }

    if (this.operatorIdleMinutes !== null) {
      this.operatorIdleMinutes = new Decimal(this.operatorIdleMinutes)
      if (this.operatorIdleTimeUnit === MINUTES && this.operatorIdleMinutes.toNumber() !== this.operatorIdleInCustomTimeUnit) {
        this.operatorIdleInCustomTimeUnit = this.operatorIdleMinutes.toNumber();
      }
    }
  }

  toBackend(...args) {
    const res = super.toBackend(...args)
    if (this.__activeCurrentRelations.includes('nextStep')) {
      if (!this.nextStep.isNew || this.nextStep.hasUserChanges) {
        res.next_step = this.nextStep.getInternalId()
      }
    }
    delete res.type


    // eslint-disable-next-line
    for (const type of Step.TYPES) {
      if (type !== this.type) {
        res[`${type}_step`] = null
      }
    }

    if (this.delayMinutes !== null) {
      res.delay_minutes = new Decimal(this.delayMinutes).toDecimalPlaces(6)
    }

    if (this.setupMinutes !== null) {
      res.setup_minutes = new Decimal(this.setupMinutes).toDecimalPlaces(6)
    }

    if (this.workMinutes !== null) {
      res.work_minutes = new Decimal(this.workMinutes).toDecimalPlaces(6)
    }

    if (this.operatorIdleMinutes !== null) {
      res.operator_idle_minutes = new Decimal(this.operatorIdleMinutes).toDecimalPlaces(6)
    }

    return res
  }

  @action toBackendAll(...args) {
    const activeCurrentRelations = this.__activeCurrentRelations

    const filterRelations = Step.TYPES.filter((type) => type !== this.type).map((type) => `${type}Step`)

    this.__activeCurrentRelations = activeCurrentRelations.filter((rel) => !filterRelations.includes(rel))

    try {
      return super.toBackendAll(...args)
    } finally {
      this.__activeCurrentRelations = activeCurrentRelations
    }
  }

  perform(data) {
    return this.wrapPendingRequestCount(this.api.post(`${this.url}perform/`, data))
  }

  multiPerform(operator, batches) {
    return this.api.post(`${this.url}multi_perform/`, {
      operator: operator.id,
      batches: batches.map(batch => batch.id)
    })
  }

}

export class StepStore extends Store {
  Model = Step
  static backendResourceName = 'step'
}

export class FormStep extends Model {
  static backendResourceName = 'form_step'

  @observable id = null
  @observable icon = 'clipboard list'

  // relations
  @observable form = this.relation(Form)
}

export class PrintStep extends Model {
  static backendResourceName = 'print_step'

  static TYPE_LABEL = 'label'
  static TYPE_DOCUMENT = 'document'
  static TYPES = ['label', 'document']

  static LABEL_PRINTER_ALTEC = 'altec'
  static LABEL_PRINTER_ZEBRA = 'zebra'
  static LABEL_PRINTERS = ['altec', 'zebra']

  @observable id = null
  @observable type = 'label'
  @observable copies = 1
  @observable defaultPrinter = null

  @observable labelPrinter = 'altec'
  @observable labelTemplate = ''

  @observable documentBackground = null
  @observable documentOverlays = []

  toBackend(...args) {
    const res = super.toBackend(...args)
    if (this.type !== 'label') {
      res.label_printer = null
      res.label_template = null
    }
    if (this.type !== 'document') {
      res.document_overlays = null
    }
    return res
  }
}

export class CarrierStep extends Model {
  static backendResourceName = 'carrier_step';
  @observable id = null;

  //Use this function to retrieve the extra service options form the backend:
  extraServiceOptions(data) {
    return this.wrapPendingRequestCount(this.api.get(`${this.url}extraServiceOptions/`, data))
  }
}

export class SplitStep extends Model {
  static backendResourceName = 'split_step'

  static TYPES = ['scan', 'print', 'article', 'provided']

  @observable id = null
  @observable icon = 'cubes'
  @observable newBatchQuantity = 1
  @observable newBatchVariableQuantity = false
  @observable newBatchVariableQuantityPredetermined = false
  @observable newBatchVariableUseOrderSize = false
  @observable type = 'print'
  @observable printer = 'altec'
  @observable template = '{{serial_number}}'
  @observable copies = 1
  @observable defaultPrinter = null

  // relations
  @observable form = this.relation(Form)

  toBackend(...args) {
    const data = super.toBackend(...args)
    if (this.type !== 'print') {
      data.printer = null
      data.template = ''
      data.copies = null
    }
    return data
  }
}

export class MultiplierStep extends Model {
  static backendResourceName = 'multiplier_step'

  @observable id = null
  @observable multiplier = 2
}

export class SubprocessesStep extends Model {
  static backendResourceName = 'subprocesses_step'

  @observable id = null
}

export class ByProductStep extends  Model {
  static backendResourceName = 'byproduct_step'

  @observable id = null
}

export class NestStep extends  Model {
  static backendResourceName = 'nest_step'

  @observable id = null

    relations() {
    return {
      nestType: NestType
    }
  }
}

export class ExportStep extends  Model {
  static backendResourceName = 'export_step'

  @observable id = null

  relations() {
    return {
      form: Form,
      integration: Integration,
    }
  }
}

export class ImportStep extends  Model {
  static backendResourceName = 'import_step'

  @observable id = null

    relations() {
    return {
      form: Form,
      integration: Integration,
    }
  }
}
